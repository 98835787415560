import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApolloClient, ApolloProvider as LibApolloProvider, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import constants from '../../constants';
import { Routes } from '../../utils/constants/routes';
import { createLogger } from '../../utils/logger';

const log = createLogger('ApolloProvider::');

export const ApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const apolloClient = useMemo(() => {
    const httpLink = createHttpLink({
      uri: process.env.API_URL,
    });

    const withToken = setContext(async (operation, { headers }) => {
      log.info('withToken: run getAccessTokenSilently', { headers });
      try {
        const accessToken = await getAccessTokenSilently();
        localStorage.setItem(constants.STORAGE_TOKEN_KEY, accessToken);
        log.info('withToken: got token', accessToken);
        return {
          headers: {
            ...headers,
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
        };
      } catch (error) {
        log.error('withToken: got error', error);
        return {
          headers: {
            ...headers,
            Authorization: '',
          },
        };
      }
    });

    const resetToken = onError(({ networkError, graphQLErrors, response }) => {
      log.error('resetToken', { response, networkError, graphQLErrors });

      graphQLErrors?.forEach((graphQLError) => {
        datadogRum.addError(graphQLError);
      });

      if (networkError && networkError.name === 'ServerError' && networkError.statusCode === 401) {
        log.info('removing the token from the local storage');
        datadogRum.addError(networkError);
        localStorage.removeItem(constants.STORAGE_TOKEN_KEY);
        navigate(Routes.LANDING);
      }
    });

    const authFlowLink = withToken.concat(resetToken);

    // TODO: do not retry if we got a validation error
    const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 3,
        retryIf: (error) => !!error,
      },
    });

    const removeTypenameLink = removeTypenameFromVariables();

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: from([removeTypenameLink, retryLink, authFlowLink, httpLink]),
      connectToDevTools: process.env.ENV_NAME === 'local',
    });
  }, [getAccessTokenSilently]);

  return <LibApolloProvider client={apolloClient}>{children}</LibApolloProvider>;
};
